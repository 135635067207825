p {
  font-size: 18px;
  color: #3d3d3d;
  font-style: arial;
}

.weber {
  font-size: 30px;
  font-weight: bold;
}

.gradient-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgb(0, 235, 252), rgb(1, 155, 142));
  border: none;
  color: white; /* 文字顏色，這裡選擇白色，但你可以按需要修改 */
  padding: 10px 20px; /* 按鈕內邊距，可根據需求調整 */
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px; /* 圓角，可根據需求調整 */
  outline: none; /* 去掉默認的外框 */
  transition: 0.3s; /* 平滑的顏色過渡效果 */
  width: 250px;
  box-shadow: 1px 1px 10px 2px rgba(2, 255, 255, 0.52);
}

.gradient-button:hover {
  background: linear-gradient(to right, rgb(1, 155, 142), rgb(0, 235, 252));
  box-shadow: 1px 1px 30px 2px rgba(0, 255, 255, 0.52);
  transition: 0.3s;
}

.StoryWord {
  margin: 15px;
  position: absolute;
  right: 35px;
  bottom: 25px;
  width: 100vh;
}

.cos_word {
  font-size: 10px;
  color: white;
}

.cos_word01 {
  font-size: 15px;
  color: white;
}

.BoxCardWord {
  width: 150px;
  font-size: 100px;
}

.BoxCardWord01 {
  font-size: 50px;
  font-weight: bold;
}

.body_style {
  background-color: #1f202b;
}

.column_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row_center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_style_00 {
  min-height: 110vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.section_style_01 {
  background-color: #1f202b;
  min-height: 110vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.MDbody {
  background-color: #1f202b;
  min-height: 110vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.cosGradient {
  background: radial-gradient(circle closest-side at center, #2e3346, #1f202b);
}

.cosGradient01 {
  background: linear-gradient(to bottom, #2e303c, #1f202b);
}

.cosGradient02 {
  background-image: linear-gradient(to top, #000000, #1f202b);
}

.section_style_02 {
  background-color: #252525;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.section_style_03 {
  background-color: #f2f1f1;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.section_style_04 {
  background-image: linear-gradient(
      to top,
      rgba(31, 32, 43, 1),
      rgba(0, 0, 0, 0.45)
    ),
    url(https://i.imgur.com/ZLpHijn.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 130vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.section_style_05 {
  background-image: linear-gradient(
      to top,
      rgba(31, 32, 43, 1),
      rgba(10, 10, 17, 0.6)
    ),
    url(https://i.imgur.com/E9xgx8K.png);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.section_style_06 {
  background-image: linear-gradient(
      to top,
      rgba(31, 32, 43, 1),
      rgba(255, 255, 255, 0.034)
    ),
    url(https://i.imgur.com/E9xgx8K.png);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.section_style_07 {
  background-image: linear-gradient(
      to top,
      rgb(31, 32, 43, 1),
      rgb(31, 32, 43, 0.3),
      rgb(31, 32, 43, 0.3),
      rgb(31, 32, 43, 1)
    ),
    linear-gradient(
      to right,
      rgb(31, 32, 43, 1),
      rgb(31, 32, 43, 0.5),
      rgb(31, 32, 43, 0.5),
      rgb(31, 32, 43, 1)
    ),
    url(https://cdn.midjourney.com/e6a4ab0e-2e3e-4046-ac1f-b53582f289d1/0_0.png);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.section_style_08 {
  background-image: linear-gradient(
      to top,
      rgb(31, 32, 43, 1),
      rgb(31, 32, 43, 0.6),
      rgb(31, 32, 43, 0.6),
      rgb(31, 32, 43, 1)
    ),
    linear-gradient(
      to right,
      rgb(31, 32, 43, 1),
      rgb(31, 32, 43, 0.5),
      rgb(31, 32, 43, 0.5),
      rgb(31, 32, 43, 1)
    ),
    url(https://cdn.midjourney.com/6e4246ed-08d9-426d-b075-d674ed6390c5/0_1.png);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.section_style_09 {
  background-image: linear-gradient(
      to top,
      rgb(31, 32, 43, 1),
      rgb(31, 32, 43, 0.3),
      rgb(31, 32, 43, 0.3),
      rgb(31, 32, 43, 1)
    ),
    linear-gradient(
      to right,
      rgb(31, 32, 43, 1),
      rgb(31, 32, 43, 0.5),
      rgb(31, 32, 43, 0.5),
      rgb(31, 32, 43, 1)
    ),
    url(https://cdn.midjourney.com/b83221f5-8377-4861-9d51-a4f27addcd01/0_1.png);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.section_style_10 {
  background-image: linear-gradient(
      to top,
      rgba(31, 32, 43, 1),
      rgba(10, 10, 17, 0.8),
      rgba(31, 32, 43, 1)
    ),
    url(https://cdn.midjourney.com/bf9bc592-62a5-4843-93d1-70b80ed462fb/0_3.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.cosCard {
  overflow: hidden;
  border-radius: 15px;
  border: 3px solid #444651;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 10px 12px 2px rgba(0, 0, 0, 0.32);
}

.cosCardA {
  width: 300px;
  overflow: hidden;
  border-radius: 15px;
  border: 3px solid #444651;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 10px 12px 2px rgba(0, 0, 0, 0.32);
  transition: 0.3s;
}

.cosCardA:hover {
  width: 360px;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  box-shadow: 36px 36px 30px 2px rgba(0, 0, 0, 0.32);
  transition: 0.3s;
}

.cosCardC {
  width: 310px;
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid rgb(83, 83, 83);
  background-color: rgb(255, 255, 255);
  box-shadow: 36px 36px 30px 2px rgba(0, 0, 0, 0.32);
  transition: 0.3s;
  padding: 30px;
}

.linkbar {
  border-radius: 30px;
  border: 1px solid white;
  display: flex;
  align-items: center;
}

.depictionCard {
  padding: 10px;
  margin: 10px 0px;
  border-radius: 8px;
  background-color: #2c3042;
}

.cosShadow {
  box-shadow: 6px 6px 5px 1px rgb(0, 0, 0, 0.12);
}

.textStyle {
  color: #ffffff;
  font-size: 15px;
  margin: 3px;
  font-weight: bold;
}

.box {
  overflow: hidden;
  border-radius: 15px;
  border: 3px solid #444651;
  background-color: rgb(255, 255, 255);
  box-shadow: 6px 10px 12px 2px rgba(0, 0, 0, 0.32);
  min-width: 22vh;
  min-height: 22vh;
  background: linear-gradient(to bottom, #2e303c, #1f202b);
  margin: 10px;
  display: flex;
  justify-content: center;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.mouse_scroll {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 55px;
}

.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);

  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;

  width: 16px;
  height: 16px;
}

.unu {
  margin-top: 1px;
}

.unu,
.doi,
.trei {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;
}

.unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;

  animation-direction: alternate;
  animation-delay: alternate;
}

.doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.2s;
  animation-direction: alternate;

  margin-top: -6px;
}

.trei {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;

  animation-delay: 0.3s;
  animation-direction: alternate;

  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid white;
  top: 170px;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: white;
  position: relative;

  height: 4px;
  width: 4px;
  border: 2px solid #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

.credit-card {
  bottom: 0px;
  right: 0px;
  width: 360px;
  height: 220px;
  /* background-color: #009293; */
  background-image: url("./img/creditcard.jpg");
  border-radius: 15px;
  padding: 20px;
  position: relative;
  perspective: 1000px;
  overflow: hidden;
  box-shadow: 6px 10px 12px 2px rgba(0, 0, 0, 0.52);
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pngShadow {
  -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.7));
}

.pngShadow02 {
  background-image: url("./img/Toystyle.png");
  width: 350px;
  height: 350px;
  background-size: cover;
  background-position: center;
  -webkit-filter: drop-shadow(12px 12px 40px rgba(146, 146, 146, 0.6));
  filter: drop-shadow(12px 12px 40px rgba(146, 146, 146, 0.6));
  transition: 0.4s;
  animation: shadow-animation 3s infinite alternate;
}

@keyframes shadow-animation {
  0% {
    width: 350px;
    height: 350px;
    -webkit-filter: drop-shadow(12px 12px 10px rgba(45, 50, 68, 0.6));
    filter: drop-shadow(12px 12px 10px rgba(45, 50, 68, 0.6));
  }
  100% {
    width: 350px;
    height: 350px;
    -webkit-filter: drop-shadow(12px 12px 80px rgba(161, 178, 237, 0.6));
    filter: drop-shadow(12px 12px 80px rgba(161, 178, 237, 0.6));
  }
}

.DCFLight {
  -webkit-filter: drop-shadow(2px 2px 10px rgba(0, 255, 234, 0.7));
  filter: drop-shadow(2px 2px 10px rgba(0, 255, 234, 0.7));
  transition: 0.4s;
  animation: DCF-animation 1s infinite alternate;
}

@keyframes DCF-animation {
  0% {
    -webkit-filter: drop-shadow(2px 2px 10px rgba(0, 255, 234, 0.7));
    filter: drop-shadow(2px 2px 10px rgba(0, 255, 234, 0.7));
  }
  100% {
    -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 255, 234, 0.7));
    filter: drop-shadow(2px 2px 1px rgba(0, 255, 234, 0.7));
  }
}

/* 導航列樣式 */
.navigation {
  background-color: #1f202b;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin: 15px 0px;
}

.navigation ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navigation li {
  margin: 0 15px;
}

.navigation a {
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  position: relative; /* 添加相對定位，以便於後面添加底線 */
}

/* 活躍的連結底線樣式 */
.navigation li.active a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: teal;
  box-shadow: 1px 1px 30px 2px rgb(0, 192, 206);
  transition: width 0.3s, transform 0.3s; /* 定義過渡效果 */
}

@media screen and (max-width: 375px) {
  .navigation li {
    margin: 0 5px; /* 調整更小的間距 */
  }

  .navigation a {
    padding: 5px 10px; /* 調整更小的內邊距 */
  }
}

.content {
  backdrop-filter: blur(8px); /* 模糊效果 */
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  background: rgba(31, 32, 43, 0.27);
  width: 400px;
  height: 450px;
  z-index: 3;
  position: absolute;
}

.contentA {
  backdrop-filter: blur(10px); /* 模糊效果 */
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  background: rgba(31, 32, 43, 0.27);
  z-index: 3;
  position: absolute;
  box-shadow: 5px 5px 10px 0px rgba(0, 2, 17, 0.8);
  border: 0.15px solid rgb(255, 255, 255, 0.2);
}

.contentB {
  backdrop-filter: blur(8px); /* 模糊效果 */
  -webkit-backdrop-filter: blur(8px);
  border-radius: 8px;
  background: rgba(31, 32, 43, 0.27);
  box-shadow: 5px 5px 10px 0px rgba(0, 2, 17, 0.8);
  border: 0.05px solid rgb(255, 255, 255, 0.2);
}

.frostedglass {
  backdrop-filter: blur(8px); /* 模糊效果 */
  -webkit-backdrop-filter: blur(10px);
  background: rgba(31, 32, 43, 0.27);
  z-index: 3;
  position: absolute;
}

.credit-card:hover {
  transition: 0.4s;
  bottom: 3px;
  right: 3px;
  box-shadow: 0px 0px 12px 8px rgb(0, 255, 234);
  border: 1px solid #c6f3f3;
}

.card-details {
  display: flex;
  alignitems: center;
  margin-bottom: 10px;
}

.polaroid {
  width: 480px; /* 根據需要調整 */
  padding: 20px 20px 60px 20px; /* 上、右、下、左的邊框。注意下方的邊框較寬 */
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加陰影效果 */
  margin: 20px; /* 為了確保有足夠的空間顯示陰影 */
  animation: polaroid-animation 1s infinite alternate;
}

.polaroid img {
  width: 100%; /* 使圖片填充整個元素 */
  display: block;
}

@keyframes polaroid-animation {
  0% {
    transform: rotate(5deg); /* 當鼠標滑過時輕微旋轉，增加互動效果 */
    box-shadow: 0 4px 80px rgba(0, 0, 0, 0.9); /* 添加陰影效果 */
  }
  100% {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加陰影效果 */
  }
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@-o-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}
@keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .order-md-1 {
    order: 2;
  }
  .order-md-2 {
    order: 1;
  }
}

.Winner {
  font-weight: bold;
  font-size: 50px;
  color: white;
}

.rulesText {
  margin: 15px;
  width: 600px;
  color: white;
  font-size: 16px;
}

.MintminerHeight {
  min-height: 50vh;
}

.minerCard {
  padding: 35px;
  margin: 10px 0px;
  border-radius: 8px;
  background-color: #2c3042;
}

/* 平板設備 */
@media (max-width: 1023px) and (min-width: 768px) {
  .BoxCardWord {
    width: 80px;
    font-size: 50px;
  }

  .StoryWord {
    width: 38vh;
  }

  .BoxCardWord01 {
    font-size: 40px;
    font-weight: bold;
  }

  .Winner {
    font-size: 40px;
  }

  .rulesText {
    width: 350px;
    font-size: 16px;
  }

  .MintminerHeight {
    min-height: 60vh;
  }

  .minerCard {
    padding: 35px;
    margin: 10px 0px;
    border-radius: 8px;
    background-color: #2c3042;
  }

  .cosCardA {
    width: 230px;
  }

  .cosCardA:hover {
    width: 240px;
  }

  .polaroid {
    width: 320px; /* 根據需要調整 */
  }

  .content {
    width: 420px;
  }
}

/* 手機設備 */

@media (max-width: 767px) {
  .section_style_04 {
    min-height: 145vh;
  }

  .MDbody {
    min-height: 250vh;
  }

  .StoryWord {
    bottom: -20px;
    width: 38vh;
  }

  .section_style_05 {
    background-image: linear-gradient(
        to top,
        rgba(31, 32, 43, 1),
        rgba(10, 10, 17, 0.75)
      ),
      url(https://i.imgur.com/E9xgx8K.png);

    min-height: 100vh;
  }

  .BoxCardWord {
    width: 70px;
    font-size: 45px;
  }

  .BoxCardWord01 {
    font-size: 35px;
    font-weight: bold;
  }

  .Winner {
    font-size: 30px;
  }

  .rulesText {
    width: 300px;
    font-size: 16px;
  }

  .MintminerHeight {
    min-height: 70vh;
  }

  .minerCard {
    padding: 15px;
    margin: 10px 0px;
    border-radius: 8px;
    background-color: #2c3042;
  }

  .cosCardC {
    width: 300px;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid rgb(83, 83, 83);
    background-color: rgb(255, 255, 255);
    box-shadow: 36px 36px 30px 2px rgba(0, 0, 0, 0.32);
    transition: 0.3s;
    padding: 30px;
  }

  .polaroid {
    width: 280px; /* 根據需要調整 */
  }

  .content {
    width: 350px;
  }
}
